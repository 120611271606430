import { clsx  } from "clsx"
import { twMerge } from "tailwind-merge"

export function cn(...inputs ) {
  return twMerge(clsx(inputs))
}

export const indianStates = [
  "Andhra Pradesh",
  "Arunachal Pradesh",
  "Assam",
  "Bihar",
  "Chhattisgarh",
  "Goa",
  "Gujarat",
  "Haryana",
  "Himachal Pradesh",
  "Jharkhand",
  "Karnataka",
  "Kerala",
  "Madhya Pradesh",
  "Maharashtra",
  "Manipur",
  "Meghalaya",
  "Mizoram",
  "Nagaland",
  "Odisha",
  "Punjab",
  "Rajasthan",
  "Sikkim",
  "Tamil Nadu",
  "Telangana",
  "Tripura",
  "Uttar Pradesh",
  "Uttarakhand",
  "West Bengal",
  // Union Territories
  "Andaman and Nicobar Islands",
  "Chandigarh",
  "Dadra and Nagar Haveli and Daman and Diu",
  "Delhi",
  "Jammu and Kashmir",
  "Ladakh",
  "Lakshadweep",
  "Puducherry",
];

export const transformFormCData = (data, documents) => {
  console.log(document);
  const transformedData = {
    userId: "507f1f77bcf86cd799439012",
    applicationId: "507f1f77bcf86cd799439013",
    formResponses: [
      {
        formId: "6745e4db250c06a38a1b99c9",
        answers: [
          {
            group_name: "basicInformation",
            data: {
              name: data.basicInformation.name,
              fatherName: data.basicInformation.fatherName,
              dateOfBirth: data.basicInformation.dateOfBirth,
              permanentAddress: {
                addressLine1: data.basicInformation.permanentAddress.addressLine1,
                addressLine2: data.basicInformation.permanentAddress.addressLine2,
                postalCode: data.basicInformation.permanentAddress.postalCode,
                state: data.basicInformation.permanentAddress.state,
                country: data.basicInformation.permanentAddress.country
              },
              presentAddress: {
                addressLine1: data.basicInformation.presentAddress.addressLine1,
                addressLine2: data.basicInformation.presentAddress.addressLine2,
                postalCode: data.basicInformation.presentAddress.postalCode,
                state: data.basicInformation.presentAddress.state,
                country: data.basicInformation.presentAddress.country
              }
            }
          },
          {
            group_name: "professionalInformation",
            data: {
             barAssociation: {
              name: data.professionalInformation.barAssociation.name,
              address: {
                addressLine1: data.professionalInformation.barAssociation.address.addressLine1,
                addressLine2: data.professionalInformation.barAssociation.address.addressLine2,
                postalCode: data.professionalInformation.barAssociation.address.postalCode,
                state: data.professionalInformation.barAssociation.address.state,
                country: data.professionalInformation.barAssociation.address.country
              }
             },
             enrollmentDetails: {
              enrollmentDate: data.professionalInformation.enrollmentDetails.enrollmentDate,
              lengthOfPractice: data.professionalInformation.enrollmentDetails.lengthOfPractice
             },
             isScheduledCasteOrTribe: data.professionalInformation.isScheduledCasteOrTribe,
             ...(data.professionalInformation.casteCertificate && { casteCertificate: data.professionalInformation.casteCertificate }),
             mainPlaceOfPractice: data.professionalInformation.mainPlaceOfPractice 
            }
          },
          {
            group_name: "legalStatusVerification",
            data: {
              isConvicted: data.legalStatusVerification.isConvicted,
              isInsolvent: data.legalStatusVerification.isInsolvent,
              isSuspended: data.legalStatusVerification.isSuspended,
              isRemoved: data.legalStatusVerification.isRemoved,
              ...(data.legalStatusVerification.removalDetails && { removalDetails: data.legalStatusVerification.removalDetails})
            }
          },
          {
            group_name: "additionalInformation",
            data: {
              hasFinancialBenefits: data.additionalInformation.hasFinancialBenefits,
              ...(data.additionalInformation.benefitDetails && { benefitDetails: data.additionalInformation.benefitDetails}),
              hasOtherOccupation: data.additionalInformation.hasOtherOccupation,
              ...(data.additionalInformation.occupationDetails && { removalDetails: data.additionalInformation.occupationDetails}),
              hasVoluntarySuspension: data.additionalInformation.hasVoluntarySuspension,
              ...(data.additionalInformation.hasVoluntarySuspension && { removalDetails: data.additionalInformation.hasVoluntarySuspension}),
              stateRollNo: data.additionalInformation.stateRollNo
            }
          },
          {
            group_name: "certification",
            data: {
              date: data.certification.date,
              declaration: {
                truthDeclaration: data.certification.truthDeclaration,
                rulesAcceptance: data.certification.rulesAcceptance
              },
              signature: data.certification.signature,
              witnesses: {
                name: data.certification.witnesses.name,
                address: {
                  addressLine1: data.certification.witnesses.address.addressLine1,
                  addressLine2: data.certification.witnesses.address.addressLine2,
                  postalCode: data.certification.witnesses.address.postalCode,
                  state: data.certification.witnesses.address.state,
                  country: data.certification.witnesses.country
                }
              }
            }
          }
        ],
        documents: documents,
      }
    ],
  }

  return transformedData;
}